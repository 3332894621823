body {
  font-family: roboto, helvetica;
}

h1, h2, h3 {
  font-size: 5em;
  text-align: center;
  color: #fff;
  font-weight: 700;
  margin-bottom: 2rem;
}
    
#section1 {
  color: #fff;
}

.fp-section {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#particles-js {
    height: 100vh;
}

#welcomeLogo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
#welcomeButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh + 80vh);
}
.jumbotron {
	margin-left: 10vw;
	margin-right: 10vw;
	background-color: transparent!important;
	color: #fff;
}

.my-2 {
	border-top: 1px solid rgba(255,255,255,1) !important;
}
.card {
	color: #fff;
	background-color: green !important;
	border: 0px solid rgba(255,255,255,1) !important;
}
.card-img-top {
	width: 100%;
    height: 5vw;
    object-fit: scale-down;
	padding-top: 3vw;
}
.ModalBody.h3 {
	color: #000;
}
Form {
	margin-left: 10vw;
	margin-right: 10vw;
}
Button.transparent {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}
Button.transparent:hover {
	background-color: transparent;
	font-weight: bold;
	border-color: #fff;
}
table {
	margin-top: 3rem;
}
tr {
	Color: #fff;
}
tr.hidden {
	display: none;
}
.info-text {
	color: #fff;
	margin-top:3rem;
}
.form-control-file {
	color: #fff;
	
}
